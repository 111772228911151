import React from 'react';

import { PercentageOffSash } from './PercentageOffSash';
import { ValueOffSashProps, ValueOffSashV2 } from './ValueOffSash';
import { HotelDiscountType, Pricing } from '@AuroraTypes';

type PricingData = Pick<
  Pricing,
  'discount' | 'discountPercentage' | 'showDiscount' | 'discountPresentation'
>;

const DiscountMapping: Record<HotelDiscountType, React.FC<ValueOffSashProps> | undefined> = {
  SUPPLIER: ValueOffSashV2,
  DEFAULT: ValueOffSashV2,
};

export type DiscountSashProps = {
  pricing: PricingData;
  isAbsoluteDiscountEnabled?: boolean;
};

export const DiscountSash: React.FC<DiscountSashProps> = ({
  pricing,
  isAbsoluteDiscountEnabled = false,
}) => {
  if (pricing.discountPresentation) {
    const Sash = DiscountMapping[pricing.discountPresentation.type];

    if (
      !isAbsoluteDiscountEnabled &&
      pricing.discountPresentation.type !== 'SUPPLIER' &&
      pricing.discountPercentage
    ) {
      return <PercentageOffSash discountPercentage={pricing.discountPercentage!} />;
    }

    if (Sash) {
      return (
        <Sash
          sx={{
            paddingX: '2xs',
            letterSpacing: '-0.04em',
          }}
          pricing={pricing}
        />
      );
    }
  }

  return null;
};

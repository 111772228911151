import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { DiscountSash } from '../card/DiscountSash';
import { Accommodation, DynamicPackageResult, ProductTrackingList } from '@AuroraTypes';
import { Button } from '@Components/Button/Button';
import { usePassengerPrice } from '@Components/Price/usePassengerPrice';
import { Prices } from '@Core/prices/Prices';
import { DepositSash } from '@Pages/search-results/card/DepositSash';
import { HolidaySummary } from '@Pages/search-results/card/HolidaySummary';
import { useProductClickTracking } from '@Tracking';

export interface HotelDetailsModalFooterProps {
  accommodation: Pick<Accommodation, 'id' | 'name' | 'localCurrency' | 'mayHaveFees'>;
  pandaUrl: string;
  offer: Pick<DynamicPackageResult, 'hotel' | 'flights' | 'pricing' | 'lowestDeposit'>;
  actionType: ProductTrackingList;
}

export const HotelDetailsModalFooter: React.FC<HotelDetailsModalFooterProps> = ({
  accommodation,
  pandaUrl,
  offer,
  actionType,
}) => {
  const { t } = useTranslation();
  const trackProductClick = useProductClickTracking();
  const lowestDepositPrice = usePassengerPrice(offer.lowestDeposit);

  return (
    <div>
      <section
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          '& > *': {
            display: 'flex',
          },
          flexWrap: 'wrap',
        }}
      >
        <div>
          <div
            sx={{
              display: ['none', 'flex'],
              flexDirection: 'column',
              alignItems: 'center',
              gap: '4xs',
            }}
          >
            <Prices
              pricing={offer.pricing}
              localCurrency={accommodation.localCurrency}
              mayHaveFees={accommodation.mayHaveFees}
            />
            {!!lowestDepositPrice && <DepositSash lowestDeposit={lowestDepositPrice} />}
            {!!offer?.pricing && (
              <DiscountSash
                pricing={offer.pricing}
                isAbsoluteDiscountEnabled
              />
            )}
          </div>
        </div>
        <div
          sx={{
            maxWidth: ['100%', '300px'],
            width: ['100%', 'auto'],
            flexDirection: 'column',
            marginLeft: [null, '2xl'],
            alignSelf: 'self-end',
          }}
        >
          <div
            sx={{
              display: ['flex', 'none'],
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}
          >
            <div
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '4xs',
              }}
            >
              {!!lowestDepositPrice && <DepositSash lowestDeposit={lowestDepositPrice} />}
              {!!offer?.pricing && (
                <DiscountSash
                  pricing={offer.pricing}
                  isAbsoluteDiscountEnabled
                />
              )}
            </div>
            <div
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                gap: '4xs',
              }}
            >
              <Prices
                pricing={offer.pricing}
                localCurrency={accommodation.localCurrency}
                mayHaveFees={accommodation.mayHaveFees}
              />
            </div>
          </div>
          <div
            sx={{
              display: ['none', 'block'],
            }}
          >
            <HolidaySummary {...offer.hotel} />
          </div>
          <Button
            variant="Primary"
            size="36"
            href={pandaUrl}
            internal
            onClick={() =>
              trackProductClick(
                [
                  {
                    masterId: accommodation.id,
                    name: offer.hotel.accommodation.name,
                    price: offer.pricing.totalPaidToUs,
                    priceIncludingPaidAtHotel: offer.pricing.totalIncludingPaidAtHotel,
                  },
                ],
                { list: actionType },
              )
            }
            sx={{
              marginTop: '3xs',
            }}
          >
            {t('searchResults.viewDeal')}
          </Button>
        </div>
      </section>
    </div>
  );
};
